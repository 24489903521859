* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .main-container {
    display: flex;
  }


  main {
    padding: 10px;
  }
  @media screen and (max-width: 480px) {
    main {
      padding:2px;
    }
  }
  .title {
    font-size: 3rem;
    display: grid;
    place-items: center;
  }
  
  /* Sidebar */
  .sidebar {
    background-color: #F7EAFF;
    color: white;
    height: 100vh;
    overflow: hidden;
  }


  
  
  .top_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 10px;
  }

  .logo {
    font-size: 18px;
    line-height: 0;
  }

  .bars {
    width: 30px;
    color:black;
  }
  
  .hide {
    display: none;
  }
  .icon{
   margin-top: 1px;
   font-size: 25px;
   color: #52525B;
 
  }
 
  .icon:hover{
    color: #6366F1!important;
  }
  .icon:active{
    color: #6366F1!important;
  }
  
  
  .routes {
    margin-top: 15px;
    gap: 30px;
    padding: 50px 15px;
  }


  .link {
    display: flex;
    color: #4B5255;
    justify-content: left;
    align-items: center;
    gap: 15px;
    padding: 10px 10px;
    border-right: 4px solid transparent;
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  }
  .link:hover {
    border-radius: 12px;
    transition: 0.2s ease-out;
    color: #6366F1!important;
  
}
  .active {
    color: #6366F1!important;
  }
  .link_text {
    white-space: nowrap;
    font-size: 17px;
    font-family: Inter;
    font-weight: 500;
    color: #52525B;
  }
  .active {
    color: #6366F1!important;
  }
  .link_text:active {
    color: #6366F1!important;
  }
  @media screen and (min-width: 400px) {
    .link_text:active {
      color: #6366F1!important;
    }
    .active {
      color: #6366F1!important;
    }
  }

  .link_text:hover {
    color: #6366F1!important;
  }
  
  .menu {
    display: flex;
    padding: 5px 10px;
    border-right: 4px solid transparent;
    transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    justify-content: space-between;
    color:#52525B;
    
  }
  .menu:active {
    color: #6366F1!important;
  }
  .menu_item {
    display: flex;
    gap: 15px;
  }
  .menu div{
    display: flex;
    justify-content: center!important;
    align-items: center!important;
    
  }
  .menu_container {
    display: flex;
    flex-direction: column;
  }
  .menu_container .link {
    padding-left: 20px;
    border-bottom: #fff 0.5px solid;
  }

  .metric-tab_active{
    display: flex;
    
  }
  .metric-tab_inactive{
    display: none;
  }
  .activeBtn{
    background-color: #8300D3;
    border: 1px solid #ffffff;
    font-weight: 400;
    color: white;
    font-size: 12px;
    border-radius: 8px;
    padding: 6px 18px;
    margin: 2px;
  }

  .InactiveBtn{
    background-color: #fff;
    border: 1px solid #ffffff;
    font-weight: 400;
    font-size: 12px;
    border-radius: 8px;
    padding: 6px 18px;
    margin:2px;
  }

  

  .activetabBtn{
    background-color: #8300D3;
    border: 1px solid #ffffff;
    font-weight: 400;
    white-space: nowrap;
    color: white;
    font-size: 14px;
    border-radius: 20px;
    padding: 6px 10px;
    margin: 2px;
  }

  .InactivetabBtn{
    background-color: transparent;
    font-weight: 400;
    white-space: nowrap;
    font-size: 14px;
    border-radius: 20px;
    padding: 6px 10px;
    margin:2px;
  }

  .slide {
    opacity: 0;
    transition-duration: 1s ease;
  
  }
  
  .slide_active {
    opacity: 1;
  }


